import React, { useEffect, useState } from 'react'
import { Button, Divider, HStack, Typography, VStack, XelaColor } from '@/XelaReact'
import { Box, LoadingOverlay } from '@mantine/core'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/Store'
import { useForm } from '@inertiajs/react'
import FileUpload from '@/XelaReact/FileUpload/FileUpload'
import { IconCheck, IconX } from '@tabler/icons-react'
import { showNotification } from '@mantine/notifications'
import { updateLeadDocumentsUploaded, updateShowB2BAddLeadModal } from '@/Store/B2B/b2bLeadSlice'

const UploadBulkDocumentsB2BForm = () => {
  const dispatch = useDispatch()
  const b2bLeadStore = useSelector((state: RootState) => state.b2bLead)
  const [loading, setLoading] = useState(false)

  const { data, setData, post, errors, reset, recentlySuccessful, clearErrors } = useForm<{
    lead_id?: number
    belongs_to: string
    documents: File[]
  }>({
    lead_id: undefined,
    belongs_to: 'Applicant',
    documents: [],
  })

  useEffect(() => {
    if (recentlySuccessful) {
      dispatch(updateLeadDocumentsUploaded(true))
      dispatch(updateShowB2BAddLeadModal(false))
      clearErrors()
      reset()
    }
  }, [recentlySuccessful])

  useEffect(() => {
    if (b2bLeadStore.b2b_lead_id !== null) {
      setData((prev) => {
        const datum: { [key: string]: string | number | undefined } = {}
        datum['lead_id'] = b2bLeadStore.b2b_lead_id

        return {
          ...prev,
          ...datum,
        }
      })
    }
  }, [b2bLeadStore.b2b_lead_id])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '10px',
        position: 'relative',
        width: '100%',
        minHeight: '380px',
        gap: '12px',
      }}
    >
      <LoadingOverlay visible={loading} overlayBlur={2} loaderProps={{ color: 'blueX' }} />
      <VStack spacing="12px" style={{ flexGrow: 1, height: '100%' }}>
        <HStack>
          <Divider variant={'dotted'}></Divider>
        </HStack>
        <HStack style={{ padding: '0 5px' }}>
          <FileUpload
            height="200px"
            error={Object.values(errors).length > 0}
            helperText={Object.values(errors).join(', <br/>')}
            typeUploadText="PDF,JPG,PNG,DOCX,XLSX (max. 200 MB)"
            options={{
              onDrop: (acceptedFiles: File[]) => {
                if (acceptedFiles.length > 0) {
                  setData((prev) => {
                    const datum: { [key: string]: File[] } = {}
                    datum['documents'] = [...prev['documents'], ...acceptedFiles]

                    return {
                      ...prev,
                      ...datum,
                    }
                  })
                }
              },
            }}
            value={null}
          ></FileUpload>
        </HStack>
        {data.documents.length > 0 && (
          <>
            <HStack>
              <Divider variant={'dotted'}></Divider>
            </HStack>
            <VStack
              spacing="4px"
              style={{
                height: '150px',
                overflowY: 'auto',
                padding: '4px',
              }}
            >
              {data.documents.map((file, index) => {
                return (
                  <HStack key={index} justifyContent="space-between">
                    <Typography variant={'body-small-bold'} style={{ width: '400px', wordBreak: 'break-all' }}>
                      {index + 1}. {file.name}
                    </Typography>
                    <Box
                      style={{
                        padding: '4px',
                        cursor: 'pointer',
                      }}
                    >
                      <IconX
                        size={16}
                        color={XelaColor.Red6}
                        onClick={() => {
                          setData((prev) => {
                            const datum: { [key: string]: File[] } = {}
                            datum['documents'] = prev.documents.filter((item, i) => i !== index)

                            return {
                              ...prev,
                              ...datum,
                            }
                          })
                        }}
                      />
                    </Box>
                  </HStack>
                )
              })}
            </VStack>
          </>
        )}
        <HStack>
          <Divider variant={'dotted'}></Divider>
        </HStack>
      </VStack>
      <HStack spacing={'12px'} justifyContent="flex-end">
        <Button
          label={'Skip'}
          variant={'secondary'}
          onClick={() => {
            showNotification({
              autoClose: 5000,
              title: 'Success',
              message: 'Lead has been created successfully.',
              color: 'green',
              icon: <IconCheck />,
            })

            dispatch(updateShowB2BAddLeadModal(false))
            clearErrors()
            reset()
          }}
        ></Button>
        <Button
          label={'Upload'}
          onClick={() => {
            setLoading(true)
            post('/documents/b2b-bulk-upload', {
              preserveState: true,
              preserveScroll: true,
              onFinish: () => {
                setLoading(false)
              },
            })
          }}
        ></Button>
      </HStack>
    </div>
  )
}

export default UploadBulkDocumentsB2BForm
